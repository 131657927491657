
import Vue from 'vue';
import Markdown from 'markdown-it';
import mda from 'markdown-it-anchor';
import toc from 'markdown-it-toc-done-right';

export default Vue.extend({
  data() {
    return {
      htmlArray: [] as string[],
      htmlArrayNames: [] as string[],
      md: undefined as any,
      showScrollToTopBtn: false,
      currentTab: 0,
      title: 'Что нового ?',
      correctName: '',
    };
  },
  computed: {
    currentHistoryVersion(): boolean {
      return this.$store.getters.getCurrentHistoryVersion();
    },
    localHistoryVersion(): string {
      return this.$store.getters.getLocalHistoryVersion();
    },
  },
  created() {
    this.md = new Markdown({ html: true, linlify: true, typographer: true, breaks: true });
    const slugify = (s: string) => encodeURI(`${s}-${this.correctName}`);
    this.md.use(mda, { slugify: (s: string) => slugify(s) });
    this.md.use(toc, { level: [3, 4], slugify: (s: string) => slugify(s) });
    const defaultImageRender = this.md.renderer.rules.image;
    this.md.renderer.rules.image = (tokens: any[], idx: number, options: any, env: any, self: any) => {
      const token = tokens[idx];
      let srcValue: string = token.attrGet('src');
      // TODO loading=lazy не залетает на принтер
      // token.attrSet('loading', 'lazy');
      if (!srcValue.startsWith('http')) {
        srcValue = encodeURI(`${this.$store.getters.getApiHostShare()}/changelog${srcValue.substr(1)}`);
        token.attrSet('src', srcValue);
      }
      return defaultImageRender(tokens, idx, options, env, self);
    };
  },
  async mounted() {
    this.$store.commit('ADD_BREADCRUMB_ITEM', { to: this.$route.path, title: this.title, maxlvl: 1 });
    this.$store.commit('SET_BROWSER_TITLE', this.title);
    await this.loadHistory();
  },
  methods: {
    onClickPrint() {
      window.print();
    },
    async loadHistory() {
      this.htmlArray = [];
      const files = await new this.$HttpModel('ИсторияВерсий_md').getRecords();
      let tryOne = false;
      for (const file of files) {
        if (file.url) {
          const res = await fetch(this.$store.getters.getApiHostShare() + file.url);
          const text = await res.text();
          const match = /##(.*)/.exec(text);
          this.correctName = file.название as string;
          const correctName = match && match[1] ? match[1].trim() : (file.название as string);
          const html = this.md.render(text);
          this.htmlArray.push(html);
          this.htmlArrayNames.push(correctName);
          if (file && file.название && !tryOne) {
            this.$store.commit('SET_LOCAL_HISTORY_VERSION', file.название);
            tryOne = true;
          }
        }
      }
    },
    onChangeTab(tabid: number) {
      this.currentTab = tabid;
    },
    onScroll(ev: Event) {
      const el: Element = ev.target as Element;
      if (el) {
        this.showScrollToTopBtn = el.scrollTop > 100;
      }
    },
    scrollToTop(tabid: number) {
      const el = this.$el.querySelector(`#sheet${tabid}`);
      el && el.scrollIntoView({ behavior: 'smooth' });
    },
  },
});
